import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

import imgBanner from "../assets/image/inner/header.jpg";
import imgC1 from "../assets/image/inner/awaludin.png";
import imgC2 from "../assets/image/inner/audiens.png";
import imgC3 from "../assets/image/inner/audiens2.png";
import imgC4 from "../assets/image/inner/tegar.png";
import teguh from "../assets/image/inner/teguh.png";
import yuyud from "../assets/image/inner/yuyud.jpg";
import abdul from "../assets/image/inner/abdul.jpg";
import agus from "../assets/image/inner/agus.jpg";
import ian from "../assets/image/inner/ian.png";
import deden from "../assets/image/inner/deden.png";
import tegar from "../assets/image/inner/tegar2.png";
import zen from "../assets/image/inner/zen.png";
import dul from "../assets/image/inner/dul.png";
import willy from "../assets/image/inner/willy.png";
import anjas from "../assets/image/inner/anjas.png";
import feri from "../assets/image/inner/feri.png";

const Tentang = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      <Helmet>
        <title>Tentang Ethical Hacker Indonesia</title>  
        <meta name="description" content="Cerita singkat tentang apa yang kami lakukan di Ethical Hacker Indonesia" />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/kontak/" />
        <meta property="og:title" content="Tentang Ethical Hacker Indonesia" />
        <meta property="og:description" content="Cerita singkat tentang apa yang kami lakukan di Ethical Hacker Indonesia" />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Tentang Ethical Hacker Indonesia"  />
        <meta name="twitter:description" content="Cerita singkat tentang apa yang kami lakukan di Ethical Hacker Indonesia" />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>  
        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h2 className="title gr-text-2 mb-9 mb-lg-12">Cerita Singkat</h2>
                  <p className="gr-text-8 mb-0">
                    Tentang apa yang kami lakukan di Ethical Hacker Indonesia
                  </p>
                </div>
              </Col>
              <Col xs="12">
                <div className="banner-fluid-image pt-lg-9">
                  <img src={imgBanner} alt="" width="1110" className="w-100 rounded-10" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-content pt-lg-28 pt-13 pb-13 pb-lg-25">
          <Container>
            <Row>
              <Col lg="6" className="mb-7 mb-lg-0">
                <div className="pr-xl-13">
                  <h2 className="gr-text-3 mb-0">
                    Kami hadir untuk menyediakan wadah bagi <i>ethical hacker</i> dan juga membantu masyarakat luas.
                  </h2>
                </div>
              </Col>
              <Col lg="6">
                <div className="pr-xl-13">
                  <p className="gr-text-8 mb-7 mb-lg-10">
                    Sejak awal berdiri 2017 lalu, kami telah mengadakan serangkaian
                    acara dan juga diskusi untuk mengedukasi "mereka yang paham".
                    Tujuannya untuk mengarahkan mereka yang memiliki skill agar menggunakan
                    kemampuan tersebut untuk hal-hal yang positif. {" "}
                  </p>
                  <p className="gr-text-8 mb-0">
                    Belakangan semenjak kasus serangan digital semakin ramai. Kami
                    kemudian memutuskan untuk terlibat langsung mengedukasi masyarakat
                    awam agar terhindar dari serangan digital. Untuk mewujudkan ini,
                    kami bekerja sama dengan beberapa organisasi, salah satunya adalah SafeNet.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content-section pt-14 pt-lg-27 pb-13 pb-lg-27 bg-default-2">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col sm="8" lg="5" xl="6" className="mb-9 mb-lg-0">
                <div className="content-grid-image-group d-flex  mx-sm-n3">
                  <div className="single-image d-flex flex-column px-3 px-sm-6">
                    <img
                      src={imgC1}
                      alt=""
                      className="w-100 pb-6 rounded-8"
                    />

                    <img
                      src={imgC2}
                      alt=""
                      className="w-100 pb-6 rounded-8"
                    />
                  </div>
                  <div className="single-image d-flex flex-column pt-11 px-3 px-sm-6">
                    <img
                      src={imgC3}
                      alt=""
                      className="w-100 pb-6 rounded-8"
                    />

                    <img
                      src={imgC4}
                      alt=""
                      className="w-100 rounded-8"
                    />
                  </div>
                </div>
              </Col>
              <Col
                sm="8"
                lg="6"
                xl="5"
                className="offset-lg-1 offset-lg-1 offset-xl-0"
              >
                <div className="content-text">
                  <h2 className="gr-text-3 mb-7 mb-lg-11">
                    Menghadirkan sarana diskusi <br className="d-none d-lg-block" />{" "}
                    yang sehat dan juga produktif.
                  </h2>
                  <p className="gr-text-8 mb-7 mb-lg-10">
                    Selama ini kami selalu berusaha agar terus 
                    mengadakan diskusi seputar apapun, salah satunya
                    dengan mengadakan <i>online</i> dan <i>offline conference</i>.{" "}
                  </p>
                  <p className="gr-text-8 mb-0">
                    Kami juga menyediakan beberapa ruang diskusi untuk siapapun.
                    Untuk orang-orang awam yang tertarik belajar seputar <i>security</i>,
                    kami sarankan untuk bergabung di group <a href="https://t.me/N00BSquad">Telegram</a> yang sudah kami buat.
                    Group di Facebook dan juga Discord diperuntukkan untuk umum.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="pt-13 pt-lg-24 pb-lg-24 ">
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <div className="section-title text-center px-md-12 mb-lg-17 mb-10">
                  <h2 className="gr-text-3 mb-7 mb-lg-8">Meet Our Team</h2>
                  <p className="gr-text-8 mb-0">
                    Orang-orang yang ada di balik layar Ethical Hacker Indonesia
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-left mb-lg-n15 mb-0">
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={teguh} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Teguh Aprianto</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={yuyud} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Yuyud Prasetyo</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={abdul} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Abdul Djalil Djayali</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={agus} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Agus Setya R</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={ian} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Armian Sangaji</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={deden} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Deden Gobel</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={zen} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Zen Rooney</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={tegar} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Tegar Purnama</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={dul} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Abdullah</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={willy} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Willy Robertus</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={anjas} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Anjaswari Curanjaya</h3>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
                <div className="team-card">
                  <div className="card-image">
                    <img src={feri} alt="" className="w-100 rounded-8" />
                  </div>
                  <div className="card-text pt-9">
                    <h3 className="gr-text-7 mb-2">Feri Harjulianto</h3>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Tentang;
